import React, { useEffect, useState } from 'react';
import axios from "../../Config/axios";
import { useAlert } from "../../../contexts/AlertContext";
import CompanyCard from "../../Dashboard/Admin/CompanyCard";
import Modal from '../../Modals/Modal';
import ActionConfirmationModal from '../../Modals/ActionConfirmationModal';
import { MANAGE_COMPANIES_FILTERS } from '../../../constants/global';
import { useTranslation } from 'react-i18next';
import Pagination from '../../UI/Pagination';
import { useSearchParams } from 'react-router-dom';

const Companies = () => {
    const { toggleLoader, triggerPopup } = useAlert();
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [companies, setCompanies] = useState([]);
	const [companyData, setCompanyData] = useState({});
    const [selectedCompany, setSelectedCompany] = useState(null);
	const [disable, setDisable] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState(MANAGE_COMPANIES_FILTERS.ALL);

    const getCompanies = async () => {
        setIsLoading(true);
        const response = await axios.get("/api/companies", {
            params: {
                per_page: 10,
				page: searchParams.get('page'),
				search: searchParams.get('search'),
				filter: searchParams.get('filter')
			},
        })
        if (response.status === 200) {
            setCompanies(response.data);
            setIsLoading(false);
		}
    };

    const triggerDeleteModal = (e) => {
		e.stopPropagation();
		setOpenModal(true);
	}

    // const deleteCompany = async (company) => {
	// 	setIsLoading(true);
	// 	setOpenModal(false);
	// 	try {
	// 		const response = await axios.delete(`/api/companies/${company.id}`, {
	// 			data: {
	// 				id: id
	// 			}
	// 		});
	// 		if (response.status === 204) {
	// 			triggerPopup('Company deleted successfully!');
    //             getCompanies();
	// 		}
	// 		setIsLoading(false);
	// 	} catch (error) {
	// 		if (error.response.status === 422) {
	// 			triggerPopup(error.response.data.message);
	// 		}
	// 		setIsLoading(false);
	// 	}
	// }

    const blockCompany = async (company) => {
		setIsLoading(true);
		setOpenModal(false);
		try {
			const response = await axios.put(`/api/block-company/${company.id}`);
			if (response.status === 200) {
				triggerPopup(response.data === 'blocked' ? t('Popups.blocked_company') : t('Popups.unblocked_company'));
                getCompanies();
			}
			setIsLoading(false);
		} catch (error) {
			if (error.response.status === 422) {
				triggerPopup(t('Popups.something_went_wrong'));
			}
			setIsLoading(false);
		}
	}

	const verifyCompany = async (id) => {
		setDisable(true);
		setIsLoading(true);
		try {
			const response = await axios.put(`/api/companies/${id}/verify`, {
				verified: 1,
			});
			if (response.status === 200) {
				setCompanyData(response.data);
				setDisable(false);
				setIsLoading(false);
				triggerPopup(t('Popups.company_verified'));
			}
		} catch (error) {
			setDisable(false);
			setIsLoading(false);
			triggerPopup(t('Popups.something_went_wrong'));
		}
	};

	const handlePageChange = (page) => {
		setSearchParams(params => {
			params.set('page', page)
			return params
		})
		setCurrentPage(page)
	}

	const handleFilter = (filter) => {
		setSearchParams(params => {
			params.set('filter', filter)
			return params
		})
		setFilter(filter)
	}

	const handleSearch = (search) => {
		setSearchParams(params => {
			params.set('search', search)
			return params
		})
		setSearch(search)
	}

	useEffect(() => {
		setCompanies(prevCompanies => ({
			...prevCompanies,
			data: (prevCompanies?.data)?.map((company) => (company.id === companyData.id ? companyData : company))
		}));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyData]);

    useEffect(() => {
		getCompanies();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter, currentPage]);

    useEffect(() => {
		toggleLoader(isLoading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);


    return (
        <>
            <Modal isOpen={ openModal }>
                <ActionConfirmationModal
                    title={selectedCompany?.blocked ? t('Modals.unblock_company_title') : t('Modals.block_company_title') }
                    description={!selectedCompany?.blocked && t('Modals.block_company_subtitle')}
                    onCancel={ () => { setOpenModal(false) } }
                    onConfirm={ () => { blockCompany(selectedCompany) } }
                />
		    </Modal>
            <div className='flex flex-col w-full gap-y-5'>
                <div className="w-fit border-2 rounded-lg grid grid-cols-4 mt-6 md:text-lg text-base">
				<button
					onClick={() => handleFilter(MANAGE_COMPANIES_FILTERS.ALL)}
					className={`${
						filter === MANAGE_COMPANIES_FILTERS.ALL ? "bg-primary text-white" : "bg-white text-primary"
					} 'col-span-1 rounded-md py-2 px-8 focus:outline-none'`}
				>
					{t('Dashboard.admin.all')}
				</button>
				<button
					onClick={() => handleFilter(MANAGE_COMPANIES_FILTERS.ACTIVE)}
					className={`${
						filter === MANAGE_COMPANIES_FILTERS.ACTIVE ? "bg-primary text-white" : "bg-white text-primary"
					} 'col-span-1 rounded-md py-2 px-8 focus:outline-none'`}
				>
					{t('Dashboard.admin.active')}
				</button>
				<button
					onClick={() => handleFilter(MANAGE_COMPANIES_FILTERS.PENDING)}
					className={`${
						filter === MANAGE_COMPANIES_FILTERS.PENDING ? "bg-primary text-white" : "bg-white text-primary"
					} 'col-span-1 rounded-md py-2 px-8 focus:outline-none'`}
				>
					{t('Dashboard.admin.pending')}
				</button>
				<button
					onClick={() => handleFilter(MANAGE_COMPANIES_FILTERS.BLOCKED)}
					className={`${
						filter === MANAGE_COMPANIES_FILTERS.BLOCKED ? "bg-primary text-white" : "bg-white text-primary"
					} 'col-span-1 rounded-md py-2 px-8 focus:outline-none'`}
				>
					{t('Dashboard.admin.blocked')}
				</button>
				</div>
				<div className="grid grid-cols-4 xl:gap-5 md:gap-2 text-xl mb-5">
					<div className="col-span-3">
						<input
							type="text"
							onChange={(e) => handleSearch(e.target.value)}
							value={search}
							placeholder={t('Dashboard.admin.search_for_a_company')}
							className="border-2 rounded-md h-full w-full placeholder:text-black p-2 text-xl"
						/>
					</div>
					<button
						onClick={() => getCompanies()}
						type="button"
						className="col-span-1 w-full bg-primary font-bold text-white p-2 rounded-md shadow-md"
					>
						{t('Dashboard.admin.search')}
					</button>
				</div>

                {(companies?.data)?.map((company, index) => (
                    <CompanyCard
                        key={index}
                        company={company}
						disable={disable}
						onVerify={ () => {verifyCompany(company.id)} }
                        onEdit={ () => {getCompanies()}}
                        onDelete={ (e) => {
                            triggerDeleteModal(e);
                            setSelectedCompany(company)
                        }}
                        onBlock={ (e) => { 
                            e.stopPropagation();
                            setOpenModal(true);
                            setSelectedCompany(company);
                        }}
                    />
                ))}

				<Pagination
					itemCount={companies?.meta?.last_page}
					itemsPerPage={currentPage}
					onPageChange={(page) => handlePageChange(page)}
				/>

                { !isLoading && (companies?.data)?.length === 0 && (
                    <p className="w-full flex justify-center text-primary text-3xl font-bold">{t('Dashboard.admin.no_results')}</p>
                )}
            </div>
        </>
    )
}

export default Companies