import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../Config/axios.js";
import GuestRequestCard from "../Requests/GuestRequestCard.js";
import { REQUESTS_SORT_BY } from "../../constants/global.js";
import useAuthContext from "../../components/Config/AuthContext";
import useAuth from "../../hooks/useAuth";
import { useAlert } from "../../contexts/AlertContext.js";
import { useSearchParams } from 'react-router-dom';

const RequestSlider = () => {

    const { t } = useTranslation();
    const { checkUserAuth } = useAuthContext();
    const { auth } = useAuth();
    const { toggleLoader } = useAlert();
    const [searchParams, setSearchParams] = useSearchParams();

    const [sortBy, setSortBy] = useState(REQUESTS_SORT_BY.LATEST);
	const [requests, setRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const swiperRef = useRef(null)

    const getRequests = async () => {
        setIsLoading(true);
		const response = await axios.post("/api/guest/get-filtered-requests", { 
            per_page: 2,
            page: 1,
            sortBy: searchParams.get('sort'),
            limit: 2
		});
		if (response.status === 200) {
			setRequests(response.data);
            setIsLoading(false);
		}
	};

    const handleSort = (sort) => {
        setSearchParams(params => {
            params.set('sort', sort)
            return params
        })
        setSortBy(sort)
    }

    useEffect(() => {
        if (typeof auth) {
            checkUserAuth()
                .finally(() => {
                    getRequests();
                });
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

    useEffect(() => {
		toggleLoader(isLoading);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading])

    return (
        <section className='bg-secondary mx-auto py-10 xl:px-20 md:px-10 px-3'>
            {/* <h2 className='font-bold text-lg md:text-2xl leading-tight text-center'>{t('Homepage.request_slider.title')}</h2> */}

            <div className="xl:mx-[12vw]">
                {(requests?.data)?.length > 0 &&
                    <div className="grid grid-cols-2 bg-white rounded-md border border-fifth w-fit mx-5">
                        <button onClick={() => { handleSort(REQUESTS_SORT_BY.LATEST) }} className={`${sortBy === REQUESTS_SORT_BY.LATEST ? 'bg-primary text-white' : 'text-primary'} rounded-md px-4 py-2`}>{t('Dashboard.requests.latest')}</button>
                        <button onClick={() => { handleSort(REQUESTS_SORT_BY.HIGHEST) }} className={`${sortBy === REQUESTS_SORT_BY.HIGHEST ? 'bg-primary text-white' : 'text-primary'} rounded-md px-4 py-2`}>{t('Dashboard.requests.highest')}</button>
                    </div>
                }

                <Swiper
                    loop={false}
                    ref={swiperRef}
                    modules={Navigation}
                    className="mySwiper"
                    direction="vertical"
                    breakpoints={{
                        320: {
                            slidesPerView: 2,
                            slidesPerGroup: 2,
                        },
                    }}
                >
                    {(requests?.data)?.length > 0 &&
                        (requests?.data)?.map((request, index) => (
                            <SwiperSlide key={index} className="px-5">
                                <GuestRequestCard
                                    key={index}
                                    request={request}
                                />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </section>
    )
}

export default RequestSlider;