import React, { useState, useEffect, useRef } from "react";
import SearchForm from "../Dashboard/SearchForm/index.js";
import { useAlert } from "../../contexts/AlertContext.js";
import axios from "../Config/axios.js";
import GuestRequestCard from "./GuestRequestCard.js";
import { t } from "i18next";
import Pagination from "../UI/Pagination.js";
import useAuthContext from "../../components/Config/AuthContext";
import useAuth from "../../hooks/useAuth";
import { MANAGE_REQUESTS_FILTERS } from "../../constants/global.js";
import { useSearchParams } from 'react-router-dom';

const Requests = () => {
    const { toggleLoader } = useAlert();
	const { checkUserAuth } = useAuthContext();
	const { auth } = useAuth();

	const isMounted = useRef(false);
	const [searchParams, setSearchParams] = useSearchParams();

	const [requests, setRequests] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [isVisible, setIsVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	
	const crsf = async () => axios.get("/sanctum/csrf-cookie");

	const getRequests = async () => {
		setIsLoading(true);

		let params = {}
		searchParams.forEach((value, key) => {
			if (key == 'zones' || key == 'parishes' || key == 'municipalities' || key == 'districts') {
				params['location'] = {
					zones: searchParams.get('zones')?.split(',') ?? [],
					parishes: searchParams.get('parishes')?.split(',') ?? [],
					municipalities: searchParams.get('municipalities')?.split(',') ?? [],
					districts: searchParams.get('districts')?.split(',') ?? []
				}
			} else {
				params[key] = value
			}
		});

		await crsf();
		const response = await axios.post("/api/guest/get-filtered-requests", { ...params });
		if (response.status === 200) {
			setRequests(response.data);
			setIsLoading(false);
		}
	};

	const handleScroll = () => {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		if (!isVisible) {
			setIsVisible(scrollTop > 100);
		}
	};

	const handlePageChange = (page) => {
		setSearchParams(params => {
			params.set('page', page)
			return params
		})
		setCurrentPage(page)
	}

	const handleSearchParams = (data) => {
		let query = {
			...data, per_page: perPage, page: currentPage, filter: MANAGE_REQUESTS_FILTERS.UNARCHIVED, verified: 1
		}
		setSearchParams(params => {
			Object.keys(query).forEach((key) => {
				if (key == 'location') {
					if (query[key].zones?.length > 0) {
						params.set('zones', query[key].zones?.join(','))
					} else {
						params.delete('zones')
					}
					if (query[key].parishes?.length > 0) {
						params.set('parishes', query[key].parishes?.join(','))
					} else {
						params.delete('parishes')
					}
					if (query[key].municipalities?.length > 0) {
						params.set('municipalities', query[key].municipalities?.join(','))
					} else {
						params.delete('municipalities')
					}
					if (query[key].districts?.length > 0) {
						params.set('districts', query[key].districts?.join(','))
					} else {
						params.delete('districts')
					}

				} else {
					params.set(key, query[key])
				}

				if (query[key] == "undefined" || query[key] == null || query[key] == "") {
					params.delete(key)
				}
			});
			return params
		})
	}

	useEffect(() => {
		getRequests(currentPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	useEffect(() => {
		if (typeof auth) {
			checkUserAuth();
		}
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		toggleLoader(isLoading);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading])

	return (
		<>
			<SearchForm searchRequests={ (data) => { handleSearchParams(data) }} hasSort hasAdditionalFields isGuest />

			<div className="flex flex-col w-full mt-10 md:mt-20">
				{(requests?.data)?.length > 0 &&
					(requests?.data)?.map((request, index) => (
						<GuestRequestCard
							key={index}
							request={request}
						/>
					))
				}

				<Pagination
					itemCount={requests?.meta?.last_page}
					itemsPerPage={currentPage}
					onPageChange={(page) => handlePageChange(page)}
				/>

			</div>
			{ !isLoading && (requests?.data)?.length === 0 && (
				<p className="w-full flex justify-center text-primary text-3xl font-bold">{t('Dashboard.admin.no_results')}</p>
			)}
		</>
	);
}

export default Requests;