import React, { useEffect, useRef, useState } from "react";
import SearchForm from "../SearchForm/index.js";
import { useAlert } from "../../../contexts/AlertContext.js";

import RequestInfo from "../../Modals/RequestInfo.js";
import ShowAmenities from "../../Modals/ShowAmenities.js";
import useAuthContext from "../../Config/AuthContext.js";

import Modal from "../../Modals/Modal.js";

import { useTranslation } from "react-i18next";
import RequestCard from "./RequestCard.js";
import Pagination from "../../UI/Pagination.js";
import { useParams, useSearchParams } from 'react-router-dom';
import { MANAGE_REQUESTS_FILTERS } from "../../../constants/global.js";

const Active = () => {
    const {
    userRequests
    } = useAuthContext();
    const { toggleLoader } = useAlert();

    const { t } = useTranslation();
    const { id } = useParams();
    const isMounted = useRef(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const [requests, setRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	const [requestDetailsModal, setRequestDetailsModal] = useState(false);
	const [requestAmenitiesModal, setRequestAmenitiesModal] = useState(false);
	const [requestReportModal, setRequestReportModal] = useState(false);

	const [selectedRequest, setSelectedRequest] = useState([]);
	const [isVisible, setIsVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

    const [step, setStep] = useState(1);

    const searchRequests = (data) => {
        setIsLoading(true);
        let params = {}
		searchParams.forEach((value, key) => {
			if (key == 'zones' || key == 'parishes' || key == 'municipalities' || key == 'districts') {
				params['location'] = {
					zones: searchParams.get('zones')?.split(',') ?? [],
					parishes: searchParams.get('parishes')?.split(',') ?? [],
					municipalities: searchParams.get('municipalities')?.split(',') ?? [],
					districts: searchParams.get('districts')?.split(',') ?? []
				}
			} else {
				params[key] = value
			}
		});

        userRequests({ ...params }).then((response) => {
            if (typeof response != "undefined") {
                if ((response.data.data)?.length > 0) {
                    setRequests(response.data);
                    setIsLoading(false);
                } else {
                    setRequests([]);
                    setIsLoading(false);
                }
            }
        });
    };

    const handlePageChange = (page) => {
        setSearchParams(params => {
			params.set('page', page)
			return params
		})
		setCurrentPage(page)
	}

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (!isVisible) {
            setIsVisible(scrollTop > 100);
        }
    };

    const handleSearchParams = (data) => {
		let query = {
			...data, per_page: perPage, page: currentPage, verified: 0
		}
		setSearchParams(params => {
			Object.keys(query).forEach((key) => {
				if (key == 'location') {
					if (query[key].zones?.length > 0) {
						params.set('zones', query[key].zones?.join(','))
					} else {
						params.delete('zones')
					}
					if (query[key].parishes?.length > 0) {
						params.set('parishes', query[key].parishes?.join(','))
					} else {
						params.delete('parishes')
					}
					if (query[key].municipalities?.length > 0) {
						params.set('municipalities', query[key].municipalities?.join(','))
					} else {
						params.delete('municipalities')
					}
					if (query[key].districts?.length > 0) {
						params.set('districts', query[key].districts?.join(','))
					} else {
						params.delete('districts')
					}

				} else {
					params.set(key, query[key])
				}

				if (query[key] == "undefined" || query[key] == null || query[key] == "" && query[key] != 0) {
					params.delete(key)
				}
			});
			return params
		})
	}

    useEffect(() => {
        if (isMounted.current) {
            if (id && requests) {
                // eslint-disable-next-line array-callback-return
                (requests?.data)?.map((request) => {
                    if (request.id == id) {
                        setSelectedRequest(request)
                        setRequestDetailsModal(true)
                    }
                })
            }
        } else {
            isMounted.current = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requests]);

    useEffect(() => {
        window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    useEffect(() => {
		searchRequests(currentPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams])


    return (
    <>
        <Modal isOpen={requestDetailsModal}>
            <RequestInfo
                request={selectedRequest}
                onClose={() => { setRequestDetailsModal(false) }}
                onViewAmenities={() => { setRequestAmenitiesModal(true) }}
                onReport={() => { setRequestReportModal(true) }}
            />
        </Modal>
        <Modal isOpen={requestAmenitiesModal}>
            <ShowAmenities
                showAmenitiesModal={() => { setRequestAmenitiesModal(false) }}
                data={selectedRequest}
            />
        </Modal>

        <div className="flex flex-col pb-20">
            <SearchForm searchRequests={ (data) => { handleSearchParams(data) }} />
        </div>
        {(requests?.data)?.length > 0 ? (
            <>
                <div className="flex flex-col w-full">
                    {(requests?.data)?.map((request, index) =>
                        request.archived === 0 && (
                            <RequestCard
                                key={index}
                                request={request}
                                onDetails={() => { setSelectedRequest(request); setRequestDetailsModal(true) }}
                                onViewAmenities={() => { setSelectedRequest(request); setRequestAmenitiesModal(true) }}
                                hasSettings={false}
                            />
                        )
                    )}
                </div>
                <Pagination
					itemCount={requests?.meta?.last_page}
					itemsPerPage={currentPage}
					onPageChange={(page) => handlePageChange(page)}
				/>
            </>
        ) : (
            <div className="w-full flex justify-center text-primary text-3xl font-bold">
                {t("Dashboard.my_requests.no_pending_requests")}
            </div>
        )}
    </>
    );
};

export default Active;
