import React, { useState, useEffect } from "react";
import SearchForm from "../SearchForm/index.js";
import { useAlert } from "../../../contexts/AlertContext.js";
import RequestInfo from "../../Modals/RequestInfo.js";
import ShowAmenities from "../../Modals/ShowAmenities.js";
import ReportRequest from "../../Modals/ReportRequest.js";
import axios from "../../Config/axios.js";
import RequestCard from "./RequestCard.js";
import { t } from "i18next";
import Modal from "../../Modals/Modal.js";
import Pagination from "../../UI/Pagination.js";
import { MANAGE_REQUESTS_FILTERS } from "../../../constants/global.js";
import { useParams, useSearchParams } from 'react-router-dom';

const Requests = () => {

	const { toggleLoader } = useAlert();
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const [requests, setRequests] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [formParams, setFormParams] = useState({ per_page: perPage, page: currentPage, filter: MANAGE_REQUESTS_FILTERS.UNARCHIVED, verified: 1 });

	const [requestDetailsModal, setRequestDetailsModal] = useState(false);
	const [requestAmenitiesModal, setRequestAmenitiesModal] = useState(false);
	const [requestReportModal, setRequestReportModal] = useState(false);

	const [selectedRequest, setSelectedRequest] = useState([]);
	const [isVisible, setIsVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const getRequests = async () => {
		setIsLoading(true);
		let params = {}
		searchParams.forEach((value, key) => {
			if (key == 'zones' || key == 'parishes' || key == 'municipalities' || key == 'districts') {
				params['location'] = {
					zones: searchParams.get('zones')?.split(',') ?? [],
					parishes: searchParams.get('parishes')?.split(',') ?? [],
					municipalities: searchParams.get('municipalities')?.split(',') ?? [],
					districts: searchParams.get('districts')?.split(',') ?? []
				}
			} else {
				params[key] = value
			}
		});

		const response = await axios.post("/api/get-filtered-requests", { ...params });
		if (response.status === 200) {
			setRequests(response.data);
			setIsLoading(false);
		}
	};

	const getRequest = async () => {
		const response = await axios.post("/api/get-filtered-requests", { id: id });
		if (id && response.data) {
			// eslint-disable-next-line array-callback-return
			(response?.data?.data)?.map((request) => {
				if (request.id == id) {
					setSelectedRequest(request)
					setRequestDetailsModal(true)
				}
			})
		}
	}

	const handleScroll = () => {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		if (!isVisible) {
			setIsVisible(scrollTop > 100);
		}
	};

	const handleRequestDetails = (request) => {
		axios.post(`/api/update-request-statistics`, {
            id: request.id,
            viewed: true
        });
		setSelectedRequest(request);
		setRequestDetailsModal(true); 
	}

	const handlePageChange = (page) => {
		setSearchParams(params => {
			params.set('page', page)
			return params
		})

		setCurrentPage(page)
	}

	const handleSearchParams = (data) => {
		let query = {
			...data, per_page: perPage, page: currentPage, filter: MANAGE_REQUESTS_FILTERS.UNARCHIVED, verified: 1
		}
		setSearchParams(params => {
			Object.keys(query).forEach((key) => {
				if (key == 'location') {
					if (query[key].zones?.length > 0) {
						params.set('zones', query[key].zones?.join(','))
					} else {
						params.delete('zones')
					}
					if (query[key].parishes?.length > 0) {
						params.set('parishes', query[key].parishes?.join(','))
					} else {
						params.delete('parishes')
					}
					if (query[key].municipalities?.length > 0) {
						params.set('municipalities', query[key].municipalities?.join(','))
					} else {
						params.delete('municipalities')
					}
					if (query[key].districts?.length > 0) {
						params.set('districts', query[key].districts?.join(','))
					} else {
						params.delete('districts')
					}

				} else {
					params.set(key, query[key])
				}

				if (query[key] == "undefined" || query[key] == null || query[key] == "") {
					params.delete(key)
				}
			});
			return params
		})
	}

	useEffect(() => {
		getRequests(currentPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams])

	useEffect(() => {
		if (id) {
			getRequest()
		}
		
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		toggleLoader(isLoading);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading])

	return (
		<>
			<Modal isOpen={requestDetailsModal}>
				<RequestInfo
					request={selectedRequest}
					onClose={() => { setRequestDetailsModal(false) }}
					onViewAmenities={() => { setRequestAmenitiesModal(true) }}
					onReport={() => { setRequestReportModal(true) }}
					hasContactInfo
				/>
			</Modal>
			<Modal isOpen={requestAmenitiesModal}>
				<ShowAmenities
					showAmenitiesModal={() => { setRequestAmenitiesModal(false) }}
					data={selectedRequest}
				/>
			</Modal>
			<Modal isOpen={requestReportModal}>
				<ReportRequest
					request={selectedRequest}
					onClose={() => { setRequestReportModal(false) }}
				/>
			</Modal>

			<div className="flex flex-col md:pb-20 pb-10">
				<div className="w-full flex justify-between items-center md:py-5 py-2 border-b-2">
					<h1 className="text-3xl font-bold text-black">{t('Dashboard.requests.requests')}</h1>
				</div>
				<SearchForm searchRequests={ (data) => { handleSearchParams(data) }} hasSort hasBuyerMatch hasAdditionalFields />
			</div>

			<div className="flex flex-col w-full">
				{(requests?.data)?.length > 0 &&
					(requests?.data)?.map((request, index) => (
						<RequestCard
							key={index}
							request={request}
							onDetails={() => { handleRequestDetails(request) }}
							onViewAmenities={() => { setSelectedRequest(request); setRequestAmenitiesModal(true) }}
							onReport={() => { setSelectedRequest(request); setRequestReportModal(true) }}
						/>
					))
				}

				<Pagination
					itemCount={requests?.meta?.last_page}
					itemsPerPage={currentPage}
					onPageChange={(page) => handlePageChange(page)}
				/>

			</div>
			{ !isLoading && (requests?.data)?.length === 0 && (
				<p className="w-full flex justify-center text-primary text-3xl font-bold">{t('Dashboard.admin.no_results')}</p>
			)}
		</>
	);
};

export default Requests;
