import React, { useState, useEffect, useRef } from "react";
import axios from "../../Config/axios";
import { useTranslation } from 'react-i18next';
import { REQUESTS_SORT_BY, COMPANY_ROLES, RANGE_FILTER, APP_PATHS, REQUEST_TYPE } from "../../../constants/global";
import { currentLanguage } from '../../Config/i18n';
import { FaFilter } from "react-icons/fa";
import Select from "../../Inputs/Select.js";
import LocationSearch from "../../Inputs/LocationSearch.js";
import PriceRange from "./PriceRange.js";
import AreaRange from "./AreaRange.js";
import useAuth from '../../../hooks/useAuth';
import CreatableSelect from "react-select/creatable";
import { Link } from "react-router-dom";
import BuyerMatchSVG from '../../../assets/svg/buyerMatchSVG.js';
import Modal from "../../Modals/Modal.js";
import SaveBuyerMatchModal from "../../Modals/SaveBuyerMatch.js";
import { useParams, useSearchParams } from 'react-router-dom';

const SearchForm = ({ searchRequests, hasSort = false, hasAdditionalFields = false, hasBuyerMatch = false, isGuest = false }) => {
  const isMounted = useRef(false);
  const { t } = useTranslation();
  const {auth} = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [sortBy, setSortBy] = useState(REQUESTS_SORT_BY.LATEST);
  const [propertyType, setPropertyType] = useState(null);
  const [requestType, setRequestType] = useState(null);
  const [id, setId] = useState('');
  const [locations, setLocations] = useState(null); // [district, municipality, zone]
  const [typology, setTypology] = useState(null);
  const [partnership, setPartnership] = useState(null);

  const [formOptions, setFormOptions] = useState([]);
  const [consultantOptions, setConsultantOptions] = useState([]);

  const [requestTypes, setRequestTypes] = useState([]);
  const [selectedRequestType, setSelectedRequestType] = useState({ id: 1 });

  const [typologies, setTypologies] = useState([]);
  const [selectedTypology, setSelectedTypology] = useState(null);

  const [partnerships, setPartnerships] = useState([]);

  const [properties, setProperties] = useState([]);

  const [isLoading, setIsLoading] = useState(false)
  const [openFilters, setOpenFilters] = useState(false)

  const [priceFrom, setPriceFrom] = useState([]);
  const [priceUntil, setPriceUntil] = useState([]);
  const [areaFrom, setAreaFrom] = useState([]);
  const [areaUntil, setAreaUntil] = useState([]);

  const [clearRanges, setClearRanges] = useState(false);

  const [consultant, setConsultant] = useState([]);
  const [openBuyerMatchModal, setOpenBuyerMatchModal] = useState(false);
  const [searchToSave, setSearchToSave] = useState({});
  const [requestTypeToSave, setRequestTypeToSave] = useState({id: null, name: t('Dashboard.my_requests.all'), name_pt: t('Dashboard.my_requests.all')});

  const customSelect = {
    control: (provided, state) => ({
      ...provided,
      borderWidth: "2px",
      borderColor: "#e5e7eb",
      width: "100%",
      borderRadius: "0.375rem"
    })
  };

  const getFormOptions = async () => {
    setIsLoading(true);
    const response = await axios.get(`/api/${isGuest ? 'guest/' : ''}get-request-form-options`);
    if (response.status === 200) {
      setFormOptions(response.data);
    }
    setIsLoading(false);
  };

  const getConsultants = async () => {
    setIsLoading(true);
    const response = await axios.post("/api/get-consultants", {
      company_id: auth?.company_id,
    });
    if (response.status === 200) {
      setConsultantOptions(response.data);
    }
    setIsLoading(false);
  };

  const submitSearch = async () => {

    if (!isMounted.current) {
      if (searchParams?.size > 0) {
        let params = {}
        searchParams.forEach((value, key) => {
          if (key == 'zones' || key == 'parishes' || key == 'municipalities' || key == 'districts') {
            params['location'] = {
              zones: searchParams.get('zones')?.split(',') ?? [],
              parishes: searchParams.get('parishes')?.split(',') ?? [],
              municipalities: searchParams.get('municipalities')?.split(',') ?? [],
              districts: searchParams.get('districts')?.split(',') ?? []
            }
          } else {
            params[key] = value
          }
        });
        return params
      }
    }

    let locations_to_search = {
      zones: [],
      parishes: [],
      municipalities: [],
      districts: [],
    };

    locations?.map((location) => {
      if (location.name) {
        return locations_to_search.zones.push(location.name)
      }
      if (location.parish?.name) {
        return locations_to_search.parishes.push(location.parish.name)
      }
      if (location.municipality?.name) {
        return locations_to_search.municipalities.push(location.municipality.name)
      }
      if (location.district?.name) {
        return locations_to_search.districts.push(location.district.name)
      }
    });

    const data = {
      propertyType: propertyType?.id,
      request_type: requestType,
      price_from: priceFrom?.value,
      price_until: priceUntil?.value,
      area_from: areaFrom?.value,
      area_until: areaUntil?.value,
      id: id,
      location: locations_to_search,
      typology: typology?.id,
      sort_by: sortBy,
      consultant_id: consultant?.id,
      partnership_id: partnership?.id,
    };

    searchRequests(data);
  };

  const onPriceChange = (newFrom, newUntil) => {
    setPriceFrom(newFrom)
    setPriceUntil(newUntil)
  }

  const onAreaChange = (newFrom, newUntil) => {
    setAreaFrom(newFrom)
    setAreaUntil(newUntil)
  }

  const loadConsultants = async (inputValue, { page }) => {
    if (!inputValue) {
        return { options: [], hasMore: false };
    }

    const response = await axios.post("/api/get-consultants", {
      company_id: auth?.company_id,
      consultant_name: inputValue,
      page: page
    });

    return {
      options: response.data,
      hasMore:
      response.data?.meta?.current_page != response.data?.meta?.last_page &&
      response.data?.length > 0,
      additional: {
        page: page + 1,
      },
    };
  };

  const clearFilters = () => {

    setPropertyType(null);
    setRequestType(null);
    setPriceFrom([]);
    setPriceUntil([]);
    setAreaFrom([]);
    setAreaUntil([]);
    setId('');
    setLocations(null);
    setTypology(null);
    setSortBy(REQUESTS_SORT_BY.LATEST);
    setConsultant(null);
    setPartnership(null);
    setClearRanges(true);

    const data = {
      sort_by: REQUESTS_SORT_BY.LATEST,
    };

    searchRequests(data);
  }

  const handleRangeClear = (field) => {
    if (field == RANGE_FILTER.MIN_BUDGET) {
      setPriceFrom(null)
    } else if (field == RANGE_FILTER.MAX_BUDGET) {
      setPriceUntil(null)
    } else if (field == RANGE_FILTER.MIN_AREA) {
      setAreaFrom(null)
    } else if (field == RANGE_FILTER.MAX_AREA) {
      setAreaUntil(null)
    } else {
      setClearRanges(false)
    }
  }

  const handleSaveBuyerMatch = () => {

    let locations_to_search = {
      zones: [],
      parishes: [],
      municipalities: [],
      districts: [],
    };

    locations?.map((location) => {
      if (location.name) {
        return locations_to_search.zones.push({id: location.id, name: location.name})
      }
      if (location.parish?.name) {
        return locations_to_search.parishes.push({id: location.parish.id, name: location.parish.name})
      }
      if (location.municipality?.name) {
        return locations_to_search.municipalities.push({id: location.municipality.id, name: location.municipality.name})
      }
      if (location.district?.name) {
        return locations_to_search.districts.push({id: location.district.id, name: location.district.name})
      }
    });

    setSearchToSave({
      propertyType: propertyType ?? null,
      requestType: requestTypeToSave ?? null,
      priceFrom: priceFrom ?? null,
      areaFrom: areaFrom ?? null,
      locations: locations_to_search ?? null,
      typology: typology ?? null,
    })

    setOpenBuyerMatchModal(true)
  }

  useEffect(() => {
    getFormOptions();
    if (!hasAdditionalFields && auth?.company_role_id === COMPANY_ROLES.ADMIN) {
      getConsultants();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    submitSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestType, sortBy]);

  useEffect(() => {
    if (isMounted.current) {
        //PROPERTIES
        setProperties(formOptions.propertyTypes);

        //REQUEST TYPES
        setRequestTypes(formOptions.requestTypes);
        if (formOptions.requestTypes?.length > 0) {
        setSelectedRequestType(formOptions.requestTypes[0]);
        }

        //TYPOLOGIES
        setTypologies(formOptions.typologies);
        if (formOptions.typologies?.length > 0) {
        setSelectedTypology(formOptions.typologies[0]);
        }

        //PARTNERSHIPS
        setPartnerships(formOptions.partnerships);
        if (formOptions.partnerships?.length > 0) {
        // setPartnership(formOptions.partnerships[0]);
        }
    } else {
        isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOptions]);

  return (
    <>

      <Modal isOpen={openBuyerMatchModal}>
        <SaveBuyerMatchModal
          search={searchToSave}
          onClose={() => {
            setOpenBuyerMatchModal(false);
          }}
        />
      </Modal>

      <button
        className={
          `${openFilters ? 'text-white bg-primary' : 'text-primary bg-white'}
          lg:hidden flex items-center gap-2 w-fit mt-4 shadow-custom rounded-full p-3 transition-all duration-200`
        }
        onClick={() => { setOpenFilters(!openFilters) }}
      >
        <FaFilter className="text-xl" />
        <p className="font-bold">
          {t('Dashboard.my_requests.filters')}
        </p>
      </button>
      
      <div className={`${openFilters ? 'block' : 'hidden'} lg:block`}>
        {requestTypes?.length !== 0 && (
          <div className="md:w-[400px] border-2 rounded-lg grid grid-cols-3 mt-6 text-sm lg:text-lg">
            <button
              className={
                requestType == null
                  ? "bg-primary text-white col-span-1 rounded-md py-2 focus:outline-none"
                  : "bg-white text-primary col-span-1 rounded-md py-2 focus:outline-none"
              }
              onClick={() => {
                setRequestType(null);
                setRequestTypeToSave({id: null, name: t('Dashboard.my_requests.all'), name_pt: t('Dashboard.my_requests.all')});
              }}
            >
              {t('Dashboard.my_requests.all')}
            </button>
            {requestTypes?.map((request_type, index) => {
              return (
                <button
                  key={index}
                  className={
                    requestType === request_type.id
                      ? "bg-primary text-white col-span-1 rounded-md py-2 focus:outline-none"
                      : "bg-white text-primary col-span-1 rounded-md py-2 focus:outline-none"
                  }
                  onClick={() => {
                    setRequestType(request_type.id);
                    setRequestTypeToSave(request_type);
                  }}
                >
                  {currentLanguage() === 'en' ? request_type.name : request_type.name_pt}
                </button>
              );
            })}
          </div>
        )}
        <div className="flex flex-col space-y-2 md:space-y-5 mt-4">
          <div className="lg:grid lg:grid-cols-4 text-lg 2xl:text-xl md:gap-2">
            { hasAdditionalFields &&
            <>
              {/* HEADERS */}
              <div className="hidden lg:grid lg:grid-cols-4 lg:col-span-4 gap-2">
                <div className="col-span-2"> {t('Dashboard.my_requests.filter_budget')}</div>
                <div className="col-span-2"> {t('Dashboard.my_requests.area')}</div>
              </div>

              <div className="col-span-2">
                <div className="lg:hidden col-span-2"> {t('Dashboard.my_requests.filter_budget')}</div>
                <PriceRange
                  range={formOptions?.priceRange}
                  requestType={requestType}
                  loading={isLoading}
                  filterMin={hasAdditionalFields}
                  onChange={(newFrom, newUntil) => { onPriceChange(newFrom, newUntil) }}
                  clear={clearRanges}
                  changeClear={(field) => handleRangeClear(field)}
                />
              </div>
              <div className="col-span-2">
                <div className="lg:hidden col-span-2 mt-4">{t('Dashboard.my_requests.area')}</div>
                <AreaRange
                  range={formOptions?.areaRange}
                  requestType={requestType}
                  loading={isLoading}
                  onChange={(newFrom, newUntil) => { onAreaChange(newFrom, newUntil) }}
                  clear={clearRanges}
                  changeClear={(field) => handleRangeClear(field)}
                />
              </div>
            </>
            }
          </div>
          {/* OTHERS */}
          <div className="md:grid md:grid-cols-2 lg:grid-cols-7 space-y-2 md:space-y-0 xl:gap-5 md:gap-2 text-lg 2xl:text-xl">
            { !hasAdditionalFields &&
            <>
              <div className="md:grid md:grid-cols-2 lg:grid-cols-7 md:col-span-2 lg:col-span-7 space-y-2 md:space-y-0 md:gap-2 text-lg 2xl:text-xl">
                {/* HEADERS */}
                <div className="hidden lg:grid lg:grid-cols-7 lg:col-span-7 gap-2">
                  <div className="col-start-3 col-span-2"> {t('Dashboard.my_requests.filter_budget')}</div>
                  <div className="col-span-2"> {t('Dashboard.my_requests.area')}</div>
                  <div className="col-span-1">ID</div>
                </div>
                <div className="hidden lg:block md:col-span-1 lg:col-span-2">
                  <Select
                    value={propertyType}
                    placeholder={'Dashboard.my_requests.property_type'}
                    options={properties}
                    onChange={(e) => { setPropertyType({...propertyType, id: e.id, name: e.name, name_pt: e.name_pt}) }}
                    style={customSelect}
                  />
                </div>  
                <div className="md:col-span-2">
                  <div className="lg:hidden col-span-2"> {t('Dashboard.my_requests.filter_budget')}</div>
                  <PriceRange
                    range={formOptions?.priceRange}
                    requestType={requestType}
                    loading={isLoading}
                    filterMin={hasAdditionalFields}
                    onChange={(newFrom, newUntil) => { onPriceChange(newFrom, newUntil) }}
                    clear={clearRanges}
                    changeClear={(field) => handleRangeClear(field)}
                  />
                </div>
                <div className="md:col-span-2">
                  <div className="lg:hidden col-span-2"> {t('Dashboard.my_requests.area')}</div>
                  <AreaRange
                    range={formOptions?.areaRange}
                    requestType={requestType}
                    loading={isLoading}
                    filterMax={hasAdditionalFields}
                    onChange={(newFrom, newUntil) => { onAreaChange(newFrom, newUntil) }}
                    clear={clearRanges}
                    changeClear={(field) => handleRangeClear(field)}
                  />
                </div>
                {/* ID */}
                <div className={`${hasAdditionalFields ? 'hidden md:col-span-1' : 'hidden lg:block md:col-span-2'} lg:col-span-1`}>
                  <input
                    onChange={(e) => setId(e.target.value)}
                    value={id}
                    type="text"
                    placeholder="ID"
                    className="border-2 px-2 rounded-md min-h-[38px] md:h-full w-full"
                  />
                </div>
              </div>
            </>
            }
            <div className="lg:hidden col-span-3 mt-2 md:-mb-2 md:mt-0">{t('Dashboard.my_requests.filter_others')}</div>
            {/* PROPERTIES */}
            <div className={`${ !hasAdditionalFields && 'lg:hidden'} col-span-2`}>
              <Select
                value={propertyType}
                placeholder={'Dashboard.my_requests.property_type'}
                options={properties}
                onChange={(e) => { setPropertyType({...propertyType, id: e.id, name: e.name, name_pt: e.name_pt}) }}
                style={customSelect}
              />
            </div>  
            {/* TYPOLOGY && PARTNERSHIP */}
            { hasAdditionalFields &&
            <>
              <div className="md:col-span-1 lg:col-span-2">
                <Select
                  value={partnership}
                  placeholder={'Dashboard.my_requests.partnership'}
                  options={partnerships}
                  onChange={(e) => { setPartnership({...partnership, id: e.id, name: e.name, name_pt: e.name_pt}) }}
                  style={customSelect}
                />
              </div>
              <div className="md:col-span-2">
                <Select
                  value={typology}
                  placeholder={'Dashboard.my_requests.typology'}
                  options={typologies}
                  onChange={(e) => { setTypology({...typology, id: e.id, name: e.name, name_pt: e.name_pt}) }}
                  style={customSelect}
                />
              </div>
            </>
            }

            {/* ID */}
            <div className={`${hasAdditionalFields ? 'md:col-span-1' : 'lg:hidden md:col-span-2'} lg:col-span-1`}>
              <input
                onChange={(e) => setId(e.target.value)}
                value={id}
                type="text"
                placeholder="ID"
                className="border-2 px-2 rounded-md min-h-[38px] md:h-full w-full"
              />
            </div>
          </div>
          <div className="md:grid md:grid-cols-1 lg:grid-cols-6 space-y-2 md:space-y-0 xl:gap-5 md:gap-2 text-lg 2xl:text-xl">
            {/* LOCATION */}
            <div className={(!hasAdditionalFields && auth.company_role_id === COMPANY_ROLES.ADMIN) ? 'md:col-span-1 lg:col-span-2' : 'col-span-6' }>
              <LocationSearch
                onChange={ (e) => { setLocations(e) } }
                placeholder={t("Dashboard.my_requests.search_by_location")}
                value={locations}
                style={customSelect}
                showIcon
              />
            </div>
            {/* CONSULTANT */}
            { (!hasAdditionalFields && auth.company_role_id === COMPANY_ROLES.ADMIN) &&
              <div className="md:col-span-1 lg:col-span-2">
                <CreatableSelect
                  placeholder={ t("Dashboard.my_requests.search_by_consultant") }
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={ consultant }
                  options={ consultantOptions }
                  onChange={ (e) => { setConsultant(e) }}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  styles={customSelect}
                />
              </div>
            }
            <div className="lg:hidden md:col-span-6 space-y-2 xl:gap-5 md:gap-2 text-lg 2xl:text-xl">
              <button
                onClick={() => submitSearch()}
                type="button"
                className="col-span-1 w-full bg-primary font-bold text-white p-2 rounded-md shadow-md"
              >
                {t('Dashboard.my_requests.search')}
              </button>

              <button
                onClick={() => clearFilters()}
                type="button"
                className="col-span-1 w-full bg-white font-bold text-primary border border-primary p-2 rounded-md shadow-md"
              >
                {t('Dashboard.my_requests.clear_filters')}
              </button>

              {hasBuyerMatch &&
                <div className="flex items-center gap-x-6">
                  <button onClick={() => handleSaveBuyerMatch()}>
                    <BuyerMatchSVG />
                  </button>
                  <Link
                    to={APP_PATHS.BUYER_MATCHES}
                    className='bg-customPurple font-bold text-white text-center py-2 px-4 rounded-md shadow-md w-full'>
                    {t('Dashboard.requests.buyer_match')}
                  </Link>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      
      <div className="flex items-end justify-between text-sm md:text-lg lg:text-sm xl:text-lg mt-5">
        {hasSort &&
          <div className={`${openFilters ? 'flex' : 'hidden'} lg:flex flex-col items-start gap-x-2`}>
            <p className="text-base">{t('Dashboard.requests.sort_by')}:</p>
            <div className="grid grid-cols-2 bg-white rounded-md border border-fifth">
              {/* <button onClick={() => { setSortBy(REQUESTS_SORT_BY.RELEVANCE) }} className={`${sortBy === REQUESTS_SORT_BY.RELEVANCE ? 'bg-primary text-white' : 'text-primary'} rounded-md px-4 py-2`}>{t('Dashboard.requests.relevance')}</button> */}
              <button onClick={() => { setSortBy(REQUESTS_SORT_BY.LATEST) }} className={`${sortBy === REQUESTS_SORT_BY.LATEST ? 'bg-primary text-white' : 'text-primary'} rounded-md px-4 py-2`}>{t('Dashboard.requests.latest')}</button>
              <button onClick={() => { setSortBy(REQUESTS_SORT_BY.HIGHEST) }} className={`${sortBy === REQUESTS_SORT_BY.HIGHEST ? 'bg-primary text-white' : 'text-primary'} rounded-md px-4 py-2`}>{t('Dashboard.requests.highest')}</button>
              {/* TODO: Trocar opção more para abrir um dropdown com as restantes opções
              <button onClick={() => { setSortBy(REQUESTS_SORT_BY.MORE) }} className={`${sortBy === REQUESTS_SORT_BY.MORE ? 'bg-primary text-white' : 'text-primary'} rounded-md px-4 py-2`}>{t('Dashboard.requests.more')}</button>
              */}
            </div>
          </div>
        }
        <div className="hidden lg:flex xl:gap-5 md:gap-2 ml-auto">
          <button
            onClick={() => submitSearch()}
            type="button"
            className="bg-primary font-bold text-white py-2 px-4 rounded-md shadow-md"
          >
            {t('Dashboard.my_requests.search')}
          </button>

          <button
            onClick={() => clearFilters()}
            type="button"
            className="bg-white font-bold text-primary border border-primary py-2 px-4 rounded-md shadow-md"
          >
            {t('Dashboard.my_requests.clear_filters')}
          </button>

          {hasBuyerMatch &&
            <div className="flex items-center xl:gap-5 md:gap-2">
              <button onClick={() => handleSaveBuyerMatch()}>
                <BuyerMatchSVG />
              </button>
              <Link
                to={APP_PATHS.BUYER_MATCHES}
                className='bg-customPurple font-bold text-white text-center py-2 px-4 rounded-md shadow-md'>
                {t('Dashboard.requests.buyer_match')}
              </Link>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default SearchForm;
