import React, { useEffect, useState } from "react";
import SearchForm from "../SearchForm/index.js";
import { useAlert } from "../../../contexts/AlertContext.js";

import useAuthContext from "../../Config/AuthContext.js";

import { useTranslation } from "react-i18next";
import RequestCard from "./RequestCard.js";
import Modal from "../../Modals/Modal.js";
import ArchiveRequestModal from "../../Modals/ArchiveRequestModal.js";
import RequestInfo from "../../Modals/RequestInfo.js";
import ShowAmenities from "../../Modals/ShowAmenities.js";
import CreateHighlights from "../../Modals/CreateHighlights.js";
import axios from "../../Config/axios.js";
import Pagination from "../../UI/Pagination.js";
import { useParams, useSearchParams } from 'react-router-dom';
import { MANAGE_REQUESTS_FILTERS } from "../../../constants/global.js";

const Archived = () => {
    const {
        userRequests,
        deleteRequest,
        checkUserAuth
    } = useAuthContext();
    const { toggleLoader, triggerPopup } = useAlert();

    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [state, setState] = useState(0);

    const [requests, setRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const [createHighlightsModal, setCreateHighlightsModal] = useState("hidden");

    const [step, setStep] = useState(1);

    const [showArchiveRequestModal, setShowArchiveRequestModal] = useState(false);
    const [selectedRequestToUnarchive, setSelectedRequestToUnarchive] = useState(null);
    const [unarchiveRequestModalTranslations, setUnarchiveRequestModalTranslations] = useState({ title: "", description: "" });

    const [unarchiveRequestModal, setUnarchiveRequestModal] = useState(null);

    const [fieldRequired, setFieldRequired] = useState(false);

    const [requestDetailsModal, setRequestDetailsModal] = useState(false);
	const [requestAmenitiesModal, setRequestAmenitiesModal] = useState(false);
	const [requestReportModal, setRequestReportModal] = useState(false);

    const [selectForHighlightRequest, setSelectForHighlightRequest] = useState(null);

	const [selectedRequest, setSelectedRequest] = useState([]);
	const [isVisible, setIsVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
    const [isSubmiting, setIsSubmiting] = useState(false);

    const searchRequests = () => {
        setIsLoading(true)
        let params = {}
		searchParams.forEach((value, key) => {
			if (key == 'zones' || key == 'parishes' || key == 'municipalities' || key == 'districts') {
				params['location'] = {
					zones: searchParams.get('zones')?.split(',') ?? [],
					parishes: searchParams.get('parishes')?.split(',') ?? [],
					municipalities: searchParams.get('municipalities')?.split(',') ?? [],
					districts: searchParams.get('districts')?.split(',') ?? []
				}
			} else {
				params[key] = value
			}
		});

        userRequests({ ...params }).then((response) => {
            console.log(response.data)
        if (typeof response != "undefined") {
            if ((response.data.data)?.length > 0) {
                setRequests(response.data);
                setIsLoading(false);
            } else {
                setRequests([]);
                setIsLoading(false);
            }
        }
        });
    };

    const hideModalHighlights = () => {
        setCreateHighlightsModal("hidden");
    };

    const unarchiveRequest = () => {
        if (selectedRequestToUnarchive != null) {
            setIsSubmiting(true);
            axios.put(`/api/toggle-archive-request/${selectedRequestToUnarchive}`)
            .then((response) => {
                setShowArchiveRequestModal(false);
                searchRequests();
                triggerPopup('Popups.request_unarchived')
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsSubmiting(false);
                checkUserAuth()
            });
        }
    };

    const handleUnarchiveRequestModal = (value, requestId = null) => {
        setSelectedRequestToUnarchive(requestId);
        setShowArchiveRequestModal((current) => value);
        setUnarchiveRequestModalTranslations({ title: "Modals.unarchive_request_title", description: "Modals.unarchive_request_subtitle" });
    };

    const deleteRequestFunction = async (id) => {
        toggleLoader(true);
        await deleteRequest(id)
        .then(() => searchRequests({}))
        .finally(() => {
            toggleLoader(false);
        });
    };

    const handlePageChange = (page) => {
		setSearchParams(params => {
			params.set('page', page)
			return params
		})
		setCurrentPage(page)
	}

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (!isVisible) {
        setIsVisible(scrollTop > 100);
        }
    };

    const handleSearchParams = (data) => {
		let query = {
			...data, per_page: perPage, page: currentPage, archived: MANAGE_REQUESTS_FILTERS.ARCHIVED, verified: 1
		}
		setSearchParams(params => {
			Object.keys(query).forEach((key) => {
				if (key == 'location') {
					if (query[key].zones?.length > 0) {
						params.set('zones', query[key].zones?.join(','))
					} else {
						params.delete('zones')
					}
					if (query[key].parishes?.length > 0) {
						params.set('parishes', query[key].parishes?.join(','))
					} else {
						params.delete('parishes')
					}
					if (query[key].municipalities?.length > 0) {
						params.set('municipalities', query[key].municipalities?.join(','))
					} else {
						params.delete('municipalities')
					}
					if (query[key].districts?.length > 0) {
						params.set('districts', query[key].districts?.join(','))
					} else {
						params.delete('districts')
					}

				} else {
					params.set(key, query[key])
				}

				if (query[key] == "undefined" || query[key] == null || query[key] == "") {
					params.delete(key)
				}
			});
			return params
		})
	}

    useEffect(() => {
        setUnarchiveRequestModal(null);
    }, [state, requests]);

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (fieldRequired) {
        triggerPopup(t("Dashboard.my_requests.fields_required"));
        setTimeout(() => {
            setFieldRequired(false);
            clearTimeout();
        }, 2500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldRequired]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
		searchRequests(currentPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams])

    return (
        <>

        <Modal isOpen={showArchiveRequestModal}>
            {showArchiveRequestModal && (
            <ArchiveRequestModal
                title={t(unarchiveRequestModalTranslations.title)}
                description={t(unarchiveRequestModalTranslations.description)}
                onCancel={() => {
                    handleUnarchiveRequestModal(false);
                }}
                onConfirm={() => {
                    unarchiveRequest();
                }}
                isLoading={isSubmiting}
            />
            )}
        </Modal>
        <Modal isOpen={requestDetailsModal}>
            <RequestInfo
                request={selectedRequest}
                onClose={() => { setRequestDetailsModal(false) }}
                onViewAmenities={() => { setRequestAmenitiesModal(true) }}
                onReport={() => { setRequestReportModal(true) }}
            />
        </Modal>
        <Modal isOpen={requestAmenitiesModal}>
            <ShowAmenities
                showAmenitiesModal={() => { setRequestAmenitiesModal(false) }}
                data={selectedRequest}
            />
        </Modal>
        <div className={createHighlightsModal}>
            <CreateHighlights
                modalHighlights={hideModalHighlights}
                requestId={selectForHighlightRequest}
            />
        </div>

        <div className="flex flex-col w-full">
            <div className="flex flex-col pb-20">
                <SearchForm searchRequests={ (data) => { handleSearchParams(data) }} />
            </div>
            {(requests?.data)?.length > 0 ? (
                <>
                    <div className="flex flex-col w-full">
                        {(requests?.data)?.map((request, index) =>
                            request.archived === 1 && (
                                <RequestCard
                                    key={index}
                                    request={request}
                                    onDetails={() => { setSelectedRequest(request); setRequestDetailsModal(true) }}
                                    onViewAmenities={() => { setSelectedRequest(request); setRequestAmenitiesModal(true) }}
                                    onArchive={() => { handleUnarchiveRequestModal(true, request.id) }}
                                    onDelete={() => { deleteRequestFunction(request.id) }}
                                />
                            )
                        )}
                    </div>
                    <Pagination
                        itemCount={requests?.meta?.last_page}
                        itemsPerPage={currentPage}
                        onPageChange={(page) => handlePageChange(page)}
                    />
                </>
            ) : (
                <div className="w-full flex justify-center text-primary text-3xl font-bold">
                    {t("Dashboard.my_requests.no_archived_requests")}
                </div>
            )}
        </div>
        </>
    );
};

export default Archived;
